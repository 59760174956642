<template>
  <div class="mb-10">
    <!--begin::Heading-->
    <h2 class="anchor fw-bolder mb-5">
      <a href="#basic-usage"></a>
      Basic usage
    </h2>
    <!--end::Heading-->

    <!--begin::Block-->
    <div class="py-5">
      Checkbox can be used alone to switch between two states.
    </div>
    <!--end::Block-->

    <div class="rounded border p-10">
      <!-- `checked` should be true or false -->
      <el-checkbox v-model="checked">Option</el-checkbox>

      <CodeHighlighter lang="html">{{ code1 }}</CodeHighlighter>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";
import CodeHighlighter from "@/view/content/highlighters/CodeHighlighter";
import {code1} from "./data.ts";

export default defineComponent({
  name: "basic-usage",
  data() {
    return {
      checked: true,
    };
  },
  components: {
    CodeHighlighter,
  },
  setup() {
    return {
      code1,
    };
  },
});
</script>
